import { batch, createSignal, createMemo, createResource, For, Show, Suspense } from "solid-js";
import { createStore, } from "solid-js/store";
import { SelectChangeEvent } from "@suid/material/Select";
import { ListItemText, TextField, FormControl, InputLabel, Select, Menu, MenuItem, ToggleButtonGroup, ToggleButton, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Typography, Button, IconButton, Stack, Card, Alert, Popover, Grid, Divider, Box } from "@suid/material";
import { AvatarWithWfButtons, showWfCandidateRecommendationItems, showWfCandidateFeedbackItems, showWfCandidateCustomerItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';

import { RateValueForTable } from '../SmallComponents/SmallRateValue';
import { FieldAddress } from "../SmallComponents/SmallFieldAddress";

import { useNavigate } from "@solidjs/router";
import { CandidateRatings } from '../Candidate/CandidateRatingComponent';
import { IndustryList } from '../MediumComponents/IndustriesComponent';
import { AccountStatusChip } from '../SmallComponents/SmallChips';
import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { candidateCVMenu } from "../../../utils/menuUtil"
import { CompetenceList } from '../SmallComponents/SmallChipsCompetence'
 
import { FieldTextValue } from "../SmallComponents/SmallFieldText";
import { CountrySelect } from '../SmallComponents/SmallFieldCountryCodeList'
import { DateInput } from '../SmallComponents/SmallFieldDate'

import { getCompanyLogoUrlChecked, isUrlValid } from '../../../utils/getCompanyLogo'
import { getDummyAvatar} from '../../../utils/getdummyavatar'
import { isExtraSmall, isSmall, isExtraLarge } from "../../../utils/sizeUtil"

import { FieldSelectList } from '../SmallComponents/SmallFieldSelectList'
import { getText, GetTextUI } from '../SmallComponents/Translate'
import { formatDateByCountryLong, formatDateByCountry, formatDateYearMonthByCountry, formatTimeByCountry, isInvoiceDueDatePassed} from "../../../utils/datetimeflag"

import { CandidateJobForReferenceFetchType, CandidateCVFetchType, CandidateAssignmentsFetchType, CandidateCompetenciesFetchType } from "../../../types/candidateCVDataTypes"
import { AddIcon, EditIcon, CloseIcon, OpenListIcon, CloseListIcon  }  from '../icons'

interface DialogHeaderProps {
    header: string;
}

const apiUrl = import.meta.env.VITE_APP_API_URL;

const countryCode = "DK"
 
const CandidateComponent = () => {

    const checkSize = isExtraLarge()

    const [open, setOpen] = createSignal(false);
    const handleClose = () => setOpen(false);

    const [openJumpStart, setOpenJumpStart] = createSignal(false);
    const handleCloseJumpStart = () => setOpenJumpStart(false);

    const [anchorElJumpStart, setAnchorElJumpStart] = createSignal<Element | null>(null);

    const [state, setState] = createStore({
        customerWWW: 'www.',
        customerAvatar: 'http://',

        jobStartDate: '',
        jobEndDate: '',
        jobName: '',
        jobRole: '',
        jobDescription: '',
        jobRoleDescription: '',
        jobSize: '',
        jobCountry: '',
        jobCity: '',
        jobAccomplished: '',
        jobAnonymized: "no",
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);

    };


    // Function to refresh job references when customerWWW changes
    const refreshJobs = async () => {
        if (isUrlValid(state.customerWWW)) {
            await refetch();
        }
    };


    createMemo(() => {
        const logoUrl = getCompanyLogoUrlChecked(state.customerWWW);
        if (logoUrl) {
            //  setState("customerAvatar", getCompanyLogoUrlChecked(state.customerWWW));
            //  setFindCustomerWWW(state.customerWWW)
            setState('customerAvatar', logoUrl);
            refreshJobs(); // Call the function to refresh job references
        }
    });

    const [findCustomerTemplateJob, setFindCustomerTemplateJob] = createSignal("");

    const handleJobChange = (event: SelectChangeEvent) => {
        //  setFindCustomerTemplateJob(event.target.value);
        const selectedId = event.target.value;
        //handleReferenceChange(selectedId)

        alert("handleJobChange: " + selectedId)

        const selectedJobReference = jobsForReferences()?.jobReferences?.find(job => job.id === selectedId);

        if (selectedJobReference) {
            setFindCustomerTemplateJob(selectedJobReference.id);
        }
    };



    const fetchCV = async (candidateId: string | null): Promise<CandidateCVFetchType> => {
        const response = await fetch(`${apiUrl}/candidate/cv/${candidateId}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('CV response was not ok.');
        }
        const data = await response.json();
        return data;
    }
    const fetchAssignments = async (candidateId: string | null): Promise<CandidateAssignmentsFetchType> => {
        const response = await fetch(`${apiUrl}/candidate/assignments/${candidateId}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('Assignments response was not ok');
        }
        const data = await response.json();
        return data;
    }
    const fetchCompetencies = async (candidateId: string | null): Promise<CandidateCompetenciesFetchType> => {
        const response = await fetch(`${apiUrl}/candidate/competencies/${candidateId}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('Competencies response was not ok');
        }
        const data = await response.json();
        return data;
    }

    const fetchJobsForReferences = async (id: string | null): Promise<CandidateJobForReferenceFetchType> => {
        //const fetchJobsForReferences = async (): Promise<CandidateJobForReferenceFetchType> => {
        const response = await fetch(`${apiUrl}/candidate/jobsforreference?urlName=${state.customerWWW}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('Job reference response was not ok');
        }
        const data = await response.json();
        return data;
    }


    const fetchId = "0"
    const [cv] = createResource<CandidateCVFetchType>(() => fetchCV(fetchId));
    const [assignment] = createResource<CandidateAssignmentsFetchType>(() => fetchAssignments(fetchId));
    const [competencies] = createResource<CandidateCompetenciesFetchType>(() => fetchCompetencies(fetchId));

    const [jobsForReferences, { refetch }] = createResource<CandidateJobForReferenceFetchType>(() => fetchJobsForReferences(state.customerWWW));
    //const [jobsForReferences, { refetch }] = createResource(state.customerWWW, fetchJobsForReferences); //

    const navigate = useNavigate();
    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }

    const handleOpenAssignment = () => {
        setOpen(true);
        handleNavigateRoute('/candidateassignment')
    }

    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const handleOpenPopover = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [openAddJobDialog, setOpenAddJobDialog] = createSignal(false);
    const handleOpenAddJobDialog = () => {
        setOpenAddJobDialog(true);
    }
    const handleCloseAddJobDialog = () => {
        setOpenAddJobDialog(false);
    }
    const handleAddJob = () => {

    }
    const [showAIBase, setShowAIBase] = createSignal<boolean>(false);
    const handleShowAIBase = () => {
        setShowAIBase(showAIBase() ? false : true)
    }

    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }
    const txtClose = getText("default", "close")
    const txtHeader = getText("jobpage", "addjob")
    const txtAdd = getText("smallcomponent", "addtag")
    const txtFromDate = getText("candidatepage", "fromdate")
    const txtToDate = getText("candidatepage", "todate")

    const [saving, setSaving] = createSignal(false);
    const [size, setSize] = createSignal({ dialogMargin: 2, dialogSpacing: 2, buttonSize: 200 })
    if (isExtraSmall())
        setSize({ dialogMargin: 1, dialogSpacing: 1, buttonSize: 40 });

    const DialogHeader = ({ header }: DialogHeaderProps) => {
        return (
            <DialogTitle>
                <Stack direction="row" spacing={size().dialogSpacing} >
                    <IconButton onClick={handleCloseAddJobDialog} > <CloseIcon /> </IconButton>
                    <Typography variant="h4">{header}</Typography>
                </Stack>
            </DialogTitle>
        )
    }
    interface footerProps {
        showUpdateButton: boolean;
    }
    const DialogFooter = (props: footerProps) => {
        return (
            <DialogActions>

                <Button variant="contained" color="info" onClick={handleAddJob}>{txtAddReferences()}</Button>

                <Button variant="outlined" color="info" onClick={handleCloseAddJobDialog}>{txtClose()}</Button>
            </DialogActions>
        )
    }

    const [anonymized, setAnonymized] = createSignal("no")

    const handleSetAnonymized = (newValue: string) => {
        setState("jobAnonymized", newValue);
    };

    const handleReferenceChange = (jobReferenceId: string) => {


        //   alert("handleReferenceChange: " + jobReferenceId)


        if (!jobReferenceId) return;
        const idx = Number(jobReferenceId);

        batch(() => {
            setState("jobName", jobsForReferences()?.jobReferences?.[idx]?.jobName as string);
            setState("jobDescription", jobsForReferences()?.jobReferences?.[idx]?.jobDescription as string);
            setState("jobStartDate", jobsForReferences()?.jobReferences?.[idx]?.jobStartDate as string);
            setState("jobEndDate", jobsForReferences()?.jobReferences?.[idx]?.jobEndDate as string);
            setState("jobRole", jobsForReferences()?.jobReferences?.[idx]?.jobRole as string);
            setState("jobRoleDescription", jobsForReferences()?.jobReferences?.[idx]?.jobRoleDescription as string);
            setState("jobSize", jobsForReferences()?.jobReferences?.[idx]?.jobSize as string);
            setState("jobCountry", jobsForReferences()?.jobReferences?.[idx]?.jobCountry as string);
            setState("jobCity", jobsForReferences()?.jobReferences?.[idx]?.jobCity as string);
        })
    }

    const txtOnsiteaddress = getText("jobpage", "onsiteaddress")
    const txtAccountVerified = getText("candidatepage", "accountverified")
    const txtAccountNotVerified = getText("candidatepage", "accountnotverified")
    const txtCurrent = getText("candidatepage", "currentassignments")
    const txtHistory = getText("candidatepage", "history")
    const txtLanguages = getText("default", "languages")
    const txtMethods = getText("default", "methods")
    const txtRoles = getText("default", "roles")
    const txtTechnical = getText("default", "technical")
    const txtAssignmentInfo = getText("candidatepage", "assignmentinfo")
    const txtCoWorkers = getText("candidatepage", "coworkers")
    const txtStaffed = getText("jobpage", "staffed") 
    const txtReferences = getText("jobpage", "jobreference")
    const txtAddReferences = getText("jobpage", "addjobreference")
    const txtCountry = getText("quicklauncher", "country")
    const txtJumpStart = getText("candidatepage", "jumpstart")
    const txtJobName = getText("jobpage", "jobname")
    const txtJobDescription = getText("jobpage", "jobdescription")
    const txtJobRole = getText("jobpage", "jobrole")
    const txtJobRoleDescription = getText("jobpage", "jobroledescription")
    const txtJobAccomplished = getText("jobpage", "jobaccomplished")
    const txtJobCity = getText("jobpage", "jobcity")
    const txtJobSize = getText("jobpage", "jobsize")

    const [showMore, setShowMore] = createSignal(false);

    const handleShowMore = (jobRoleId: string) => {
        setShowMore(!showMore())
        setOpen(true);
        handleNavigateRoute(`/myjobrole/${jobRoleId}`)
    }
    const txtMore = getText("default", "more")
    const txtLess = getText("default", "less")


    // ****************************************************************
    // REMEMBER THIS: cv()?.candidate?.[0]?.verified ******************
    // ****************************************************************
    // {cv()?.candidate?.[0]?.src}
    // {/* ? cv()?.candidate?.[0]?.src as string : "/assets/imgs/avatar2.jpg" */}


    return (
        <>
            {/* Present Candidate Profile section for Customer */}

            <Card sx={{ margin: 1 }}>

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <For each={cv()?.candidate}>
                        {(item, idx) => (
                            <>
                                <Grid container   >

                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                                        {/* Addition navigation options for the customer */}
                                        <Stack direction="row" justifyContent="left" alignContent="center" margin={1} spacing={1}>
                                            <PageListMenu options={candidateCVMenu} handleEvent={handleOpenFilter} />
                                        </Stack>

                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                        <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                                            {/* Present Candidates account status */}
                                            <AccountStatusChip >{item.verified ? txtAccountVerified() : txtAccountNotVerified() as string}</AccountStatusChip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Box sx={{ p: 2, display: 'flex' }}>

                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                            <AvatarWithWfButtons src={item?.src} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h4">{item?.firstName} {item.lastName}</Typography>
                                                <IconButton size="small" onClick={() => handleNavigateRoute("/candidatecvedit")}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Stack>
                                            <Typography variant="h5">{item?.title}</Typography>
                                            <Typography  >{item?.education}</Typography>
                                            <FieldAddress type="view"
                                                id="adresse" label={txtOnsiteaddress()} startValue={(item?.country + ", " + item?.city)}
                                            />
                                            <Stack direction="row" spacing={0.5}>
                                                <ShowSocialMedia SoMes={cv()?.candidateSOMEs} />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>

                                            {/* Candidates main profile description */}
                                            <Popover
                                                id="mouse-over-popover" sx={{ pointerEvents: "none" }}
                                                open={openPopover()}
                                                anchorEl={anchorEl()}
                                                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                                                transformOrigin={{ vertical: "top", horizontal: "left", }}
                                                onClose={handleClosePopover}
                                                disableRestoreFocus
                                            >
                                                <Stack direction="column" spacing={2} margin={2}>

                                                    <Typography color="text.primary">
                                                        <GetTextUI formName={"candidatepage"} label={"aidisclaimer"} />
                                                    </Typography>
                                                    <Divider />
                                                    <Typography color="text.secondary"> <GetTextUI formName={"candidatepage"} label={"originaltext"} /> </Typography>
                                                    <Card>
                                                        <Stack direction="column" spacing={1} margin={1}>
                                                            <Typography fontSize="80%" color="text.secondary">
                                                                {item?.description}
                                                            </Typography>
                                                        </Stack>
                                                    </Card>

                                                </Stack>
                                            </Popover>
                                            <Stack direction="column" spacing={2} margin={2}>
                                                <div style={{ position: 'relative' }}>
                                                    <Typography

                                                        aria-haspopup="true"
                                                        color="text.secondary"     >
                                                        <br />
                                                        {item?.description}
                                                    </Typography>


                                                </div>
                                                {/* Present Candidate rating for Customer */}
                                                <br />
                                                <CandidateRatings rating={3.5} numberOfFeedbacks={1} />
                                            </Stack>
                                        </Grid>

                                    </Grid>

                                </Box>

                                <Divider />

                                {/* Relevant info for the candidate's other activities */}
                                <Alert sx={{ px: 1, py: 1 }} severity="info">
                                    {item.firstName} <GetTextUI formName={"candidatepage"} label={"alert1"} />. <br />
                                    <GetTextUI formName={"candidatepage"} label={"alert2"} />
                                </Alert>
                            </>
                        )}
                    </For>
                    <Divider />

                    <Suspense fallback={
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"default"} label={"loading"} />.
                        </Alert>
                    }>
                        <Show when={assignment()?.assignments?.length}>
                            <CardWithMinimize level={1} header={txtCurrent() + " (" + assignment()?.assignments?.length + ")" as string} type="stack" defaultOpen={true}>
                                <Grid container  >
                                    {/* Show each of the two Grid items in a row if screen is equal or larger than a medium screen */}
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* Candidates current assignment for the customer */}

                                        <For each={assignment()?.assignments}>
                                            {(item, idx) => (
                                                <>
                                                    <CardWithMinimize iconName={String(idx() + 1)} header={txtAssignmentInfo() as string} type="stack" defaultOpen={true}>

                                                        <Stack direction="column" spacing={1} margin={1}>
                                                            <Stack direction="row" >
                                                                <Typography color="text.secondary">
                                                                    {cv()?.candidate?.[0]?.firstName}


                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentstarted"} />
                                                                    {formatDateYearMonthByCountry(item?.jobStartDate)}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentassignedfor"} />
                                                                    {formatDateYearMonthByCountry(item?.jobEndDate)}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentassignedas"} />
                                                                    {item?.role}

                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentforthe"} />
                                                                    {item?.jobName}.
                                                                </Typography>
                                                            </Stack>
                                                            <Stack direction="row" >
                                                                <Typography color="text.secondary">
                                                                    {cv()?.candidate?.[0]?.firstName}
                                                                    <GetTextUI formName={"candidatepage"} label={"assignmentispaid"} />

                                                                </Typography>
                                                              
                                                                <Show when={item?.rateFlat}>
                                                                    <RateValueForTable displayType="smallPrimary" value={Number(item?.rateFlat)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary"> (<GetTextUI formName={"smallcomponent"} label={"flatrate"} />)  </Typography>
                                                                </Show>
                                                                <Show when={item?.rateOnsite}>
                                                                    <RateValueForTable displayType="smallPrimary" value={Number(item?.rateOnsite)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary">  (<GetTextUI formName={"smallcomponent"} label={"onsite"} />)  </Typography>
                                                                </Show>
                                                                <Show when={item?.rateRemote}>
                                                                    <RateValueForTable displayType="smallPrimary" value={Number(item?.rateRemote)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} /><Typography color="text.secondary">  (<GetTextUI formName={"smallcomponent"} label={"remote"} />)  </Typography>
                                                                </Show>
                                                            </Stack>

                                                            <Stack direction="row" spacing={1}>
                                                                <CandidateRatings showWithFilter={false} rating={4.5} numberOfFeedbacks={1} />
                                                            </Stack>

                                                            <Box alignContent="left">
                                                                <Button onClick={ (event) => handleShowMore(item?.avatarId)} color="inherit" size="small" variant="text" startIcon={showMore() ? <OpenListIcon /> : <CloseListIcon />}>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {showMore() ? txtLess() : txtMore()}
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Stack>
                                                    </CardWithMinimize>
                                                </>
                                            )}
                                        </For>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CardWithMinimize header={txtCoWorkers() as string + " (" + assignment()?.feedbacksAskFor?.length + ")"} type="stack" defaultOpen={true}>
                                            <For each={assignment()?.feedbacksAskFor}>
                                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateRecommendationItems} jobCustomer={item.jobCustomer} avatarSize="medium" avatarName={item.avatarName} role={item.role} jobStartDate={item.jobStartDate} src={item?.src ? item?.src : getDummyAvatar()} />}
                                            </For>
                                        </CardWithMinimize>
                                    </Grid>
                                </Grid>
                            </CardWithMinimize>
                        </Show>
                    </Suspense>

                    {/* Industry list */}
                    <Card sx={{ margin: 2, display: 'flex' }}>
                        <IndustryList valueList={cv()?.candidateIndustries} />
                    </Card>

                    {/* List of Candidates different types of competencies */}
                    <Grid container spacing={1} >

                        {/* Show each of the two Grid items in a row if screen is equal or larger than a medium screen */}
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <CompetenceList showAddButton={true} type="language" direction="row" header={txtLanguages() as string} options={competencies()?.languages} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <CompetenceList showAddButton={true} type="method" direction="row" header={txtMethods() as string} options={competencies()?.methods} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <CompetenceList showAddButton={true} type="role" direction="row" header={txtRoles() as string} options={competencies()?.roles} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <CompetenceList showAddButton={true} type="technical" direction="row" header={txtTechnical() as string} options={competencies()?.technicals} />
                        </Grid>

                    </Grid>

                    {/* List of customers at the bottom of the CV profile page */}
                    <Suspense fallback={
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"default"} label={"loading"} />.
                        </Alert>}>
                        <CardWithMinimize level={1} header={txtReferences() + " (" + cv()?.candidateCustomers?.length + ")" as string} type="stack" defaultOpen={true}>
                            <Avatar onClick={handleOpenAddJobDialog} variant="rounded" sx={{ margin: 2, display: 'flex', width: 50, height: 120, cursor: 'pointer' }}   > {<AddIcon fontSize="large" />} </Avatar>

                            <For each={cv()?.candidateCustomers}>
                                {(item) => (
                                    <>
                                        <AvatarWithWfButtons
                                            avatarSize="medium"
                                            showWfItems={showWfCandidateCustomerItems}
                                            avatarName={item.avatarName}
                                            jobCustomer={item.jobCustomer}
                                            jobName={item.jobName}
                                            jobDescription={item.jobDescription}
                                            locationCountry={item.jobCountry}
                                            locationCity={item.jobCity}
                                            jobAccomplishment={item.jobAccomplishment}
                                            jobSize={item.jobSize}
                                            anonymized={!item?.anonymized ? "no" : (item.anonymized === "no" ? "no" : "editModus")}
                                            rating={item.rating}
                                            numberOfRatings={item.numberOfRatings}
                                            jobStartDate={formatDateYearMonthByCountry(item.jobStartDate)}
                                            jobEndDate={formatDateYearMonthByCountry(item.jobEndDate)}
                                            role={item.role}
                                            roleDescription={item.roleDescription}
                                            src={item?.src ? item?.src : getDummyAvatar()}
                                        />
                                    </>
                                )}
                            </For>
                        </CardWithMinimize>

                        {openAddJobDialog() && (
                            <Dialog open={true} onBackdropClick={handleCloseAddJobDialog} fullScreen >
                                <Box
                                    component="form"
                                    onSubmit={(e) => {
                                        e.preventDefault();

                                    }}
                                >
                                    <DialogHeader header={txtAddReferences() as string} />
                                    <DialogContent>



                                        <Card >

                                            <Stack margin={size().dialogMargin}>

                                                <Grid container spacing={size().dialogSpacing} >



                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                                        <Stack direction={"row"} spacing={size().dialogSpacing} margin={size().dialogMargin}>

                                                            <FieldTextValue id="customerWWW" label="Customer Website" textValue={state.customerWWW} valueOnChange={(newValue) => handleChange('customerWWW', newValue)} />

                                                            <Avatar variant="rounded" sx={{ margin: 2, display: 'flex', width: 40, height: 40 }} src={state.customerAvatar}  >   </Avatar>

                                                        </Stack>

                                                        <Stack direction={isExtraSmall() ? "column" : "row"} spacing={size().dialogSpacing} margin={size().dialogMargin} alignItems="center">

                                                            <Show when={isUrlValid(state.customerWWW) === true && jobsForReferences()?.jobReferences?.length}
                                                                fallback={
                                                                    <>
                                                                        <Alert severity="info" sx={{ width: "100%" }}>


                                                                            <Typography variant="body2" color="text.secondary"><GetTextUI formName={"candidatepage"} label={"jumpstartno"} /></Typography>
                                                                        </Alert>
                                                                    </>

                                                                }
                                                            >
                                                                <Alert color="success" sx={{ width: "100%" }}
                                                                    action={
                                                                        <Button
                                                                            title={txtJumpStart()}
                                                                            onClick={(event) => { setAnchorElJumpStart(event.currentTarget); setOpenJumpStart(true) }}
                                                                            size="small"
                                                                            sx={{ ml: 2 }}
                                                                            aria-controls={openJumpStart() ? "account-menu" : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={openJumpStart() ? "true" : undefined}
                                                                            color="primary"
                                                                        ><CloseListIcon />

                                                                        </Button>
                                                                    }>
                                                                    <GetTextUI formName={"candidatepage"} label={"jumpstartexplain"} />
                                                                </Alert>
                                                            </Show>
                                                        </Stack>

                                                        <Menu
                                                            anchorEl={anchorElJumpStart()}
                                                            id="account-menu"
                                                            open={openJumpStart()}
                                                            onClose={handleCloseJumpStart}
                                                            onClick={handleCloseJumpStart}>
                                                            <For each={jobsForReferences()?.jobReferences}>
                                                                {(jobReference, idx) => (
                                                                    <MenuItem value={jobReference.id} onClick={event => handleReferenceChange(String(idx()))} >
                                                                        <ListItemText
                                                                            primary={jobReference.jobName + " (start " + formatDateYearMonthByCountry(jobReference?.jobStartDate) + ")"}
                                                                            secondary={jobReference?.jobDescription}
                                                                        />

                                                                    </MenuItem>
                                                                )}
                                                            </For>

                                                        </Menu>



                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                                                        <DateInput
                                                            value={state.jobStartDate}
                                                            defaultValue={state.jobStartDate}
                                                            id="dateidfrom"
                                                            label={txtFromDate()}
                                                            onChange={(newValue) => handleChange('jobStartDate', newValue)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <DateInput
                                                            value={state.jobEndDate}
                                                            defaultValue={state.jobEndDate}
                                                            id="dateidto"
                                                            label={txtToDate()}
                                                            onChange={(newValue) => handleChange('jobEndDate', newValue)}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FieldTextValue id="jobName" label={txtJobName()} textValue={state.jobName} valueOnChange={(newValue) => handleChange('jobName', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FieldTextValue id="jobRole" label={txtJobRole()} textValue={state.jobRole} valueOnChange={(newValue) => handleChange('jobRole', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FieldTextValue id="jobDescription" label={txtJobDescription()} multiline={true} textValue={state.jobDescription} valueOnChange={(newValue) => handleChange('jobDescription', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FieldTextValue id="roleDescription" label={txtJobRoleDescription()} multiline={true} textValue={state.jobRoleDescription} valueOnChange={(newValue) => handleChange('jobRoleDescription', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <FieldTextValue id="jobAccomplished" label={txtJobAccomplished()} multiline={true} textValue="" valueOnChange={(newValue) => handleChange('jobAccomplished', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <FieldTextValue id="jobSize" label={txtJobSize()} textValue={state.jobSize} valueOnChange={(newValue) => handleChange('jobSize', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <CountrySelect defaultValue={state.jobCountry ? state.jobCountry : 'da'} id="countryId" label={txtCountry() as string} type="Country" valueOnChange={(newValue) => handleChange('jobCountry', newValue)} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FieldTextValue id="jobCity" label={txtJobCity()} textValue={state.jobCity} valueOnChange={(newValue) => handleChange('jobCity', newValue)} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography variant="body2" color="text.secondary">  <GetTextUI formName={"default"} label={"anonymized"} /> </Typography>
                                                        <Stack direction={isExtraSmall() ? "column" : "row"} spacing={size().dialogSpacing} margin={size().dialogMargin}>

                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={anonymized()}
                                                                orientation={isExtraSmall() ? "vertical" : "horizontal"}
                                                                exclusive
                                                                onChange={(event, newAlignment) => {
                                                                    handleSetAnonymized(newAlignment);
                                                                }}
                                                            >
                                                                <ToggleButton value="no"> <GetTextUI formName={"default"} label={"no"} /></ToggleButton>
                                                                <ToggleButton value="logo"><GetTextUI formName={"candidatepage"} label={"logoonly"} /></ToggleButton>
                                                                <ToggleButton value="all"><GetTextUI formName={"candidatepage"} label={"logoandname"} /></ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Stack>
                                                        <Typography variant="body2" color="text.secondary">  <GetTextUI formName={"candidatepage"} label={"anonymizedexplaincv"} /> </Typography>
                                                    </Grid>



                                                </Grid>
                                            </Stack>
                                        </Card>

                                    </DialogContent>
                                    <DialogFooter showUpdateButton={true} />
                                </Box>
                            </Dialog>
                        )}
                    </Suspense>
                </Suspense>
            </Card>
            <br />
            <br />
        </>
    );
}

export { CandidateComponent };