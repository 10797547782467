import { PageTemplate } from './PageTemplate'
import { CandidateComponent } from '../components/Candidate/CandidateComponent'

const ShowCandidateMyCVPage = () => { 
  return (
    <PageTemplate
      type="candidate" headerPage="candidatepage" headerElement="mycv"
      leftPaneComponent={null} mainComponent={CandidateComponent()} buttonComponent={null} >
    </PageTemplate>
  );
}
export { ShowCandidateMyCVPage } 